<template>
  <div class="bg-white">
    <section aria-labelledby="features-heading" class="mx-auto max-w-7xl pt-32 pb-12 sm:px-2 lg:px-8">
      <div class="mx-auto max-w-7xl px-4 lg:max-w-none lg:px-0">
        <div class="max-w-7xl mx-auto border-b border-black">
          <h2 id="features-heading" class="text-xl text-left leading-10 mx-auto max-w-7xl pb-2 font-bold tracking-tight text-gray-400 sm:text-xl">' PAINT IT BLACK '  è su Come Ristrutturare La Casa, Marzo - Aprile 2024,</h2>
        </div>

        <div>
          <div class="mx-auto max-w-full py-12 sm:py-12">
            <div class="mx-auto max-w-2xl lg:max-w-none">
              <div class="max-w-3xl">
                <p class="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">Rinascita in nero</p>
                <p class="mt-4 text-gray-500">La trasformazione di un bilocale a Viareggio in un open space dominato dal nero, con dettagli in legno di rovere, un opera di design su misura che ottimizza spazio e funzionalità</p>
                <a
                    href="img/pubblicazioni/CRC_marzo_aprile_2024/crc_marzo_2024_copertina.webp"
                    data-fancybox="gallery2"
                    data-caption="Cose di Casa - 8 Agosto 2022"
                    class="hover:text-indigo-400 flex my-10"
                >
                  Sfoglia
                  <DocumentSearchIcon class="h-6 w-6 ml-1"/>

                </a>
              </div>

              <div class="mt-10 space-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16">

                <div class="overflow-hidden object-cover object-center mx-auto">
                  <a
                      href="img/pubblicazioni/CRC_marzo_aprile_2024/pagina1.webp"
                      data-fancybox="gallery2"
                      data-caption="Il wellness a casa"
                  >
                    <img src="img/pubblicazioni/CRC_marzo_aprile_2024/pagina1.webp" alt="Il wellness a casa" class="aspect-w-3 aspect-h-2 object-cover  sm:pr-2 lg:pr-12 float-end" />
                  </a>
                </div>

                <div class="overflow-hidden object-cover object-center mx-auto">
                  <a
                      href="img/pubblicazioni/CRC_marzo_aprile_2024/pagina2.webp"
                      data-fancybox="gallery2"
                      data-caption="Luogo di rigenerazionze"
                  >
                    <img src="img/pubblicazioni/CRC_marzo_aprile_2024/pagina2.webp" alt="Luogo di rigenerazionze" class="aspect-w-3 aspect-h-2 object-cover object-center" />
                  </a>
                </div>

                <div class="overflow-hidden object-cover object-center mx-auto">
                  <a
                      href="img/pubblicazioni/CRC_marzo_aprile_2024/pagina3.webp"
                      data-fancybox="gallery2"
                      data-caption="Relax e integrazione"
                  >
                    <img src="img/pubblicazioni/CRC_marzo_aprile_2024/pagina3.webp" alt="Relax e integrazione" class="aspect-w-5 aspect-h-2 object-cover object-center" />
                  </a>
                </div>

                <div class="overflow-hidden object-cover object-center mx-auto">
                  <a
                      href="img/pubblicazioni/CRC_marzo_aprile_2024/pagina4.webp"
                      data-fancybox="gallery2"
                      data-caption="Spa in camera"
                  >
                    <img src="img/pubblicazioni/CRC_marzo_aprile_2024/pagina4.webp" alt="Spa in camera" class="aspect-w-5 aspect-h-2 object-cover object-center" />
                  </a>
                </div>

                <div class="overflow-hidden object-cover object-center mx-auto">
                  <a
                      href="img/pubblicazioni/CRC_marzo_aprile_2024/pagina5.webp"
                      data-fancybox="gallery2"
                      data-caption="Spa in camera"
                  >
                    <img src="img/pubblicazioni/CRC_marzo_aprile_2024/pagina5.webp" alt="Spa in camera" class="aspect-w-5 aspect-h-2 object-cover object-center" />
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>

</template>

<script>
import { DocumentSearchIcon, } from '@heroicons/vue/outline'

export default {
  components: {
    DocumentSearchIcon,

  },
}

</script>